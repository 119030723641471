<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-inline" style="padding-bottom: 0px;">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Author information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body" >

        <div class="row">
          <div class="col-md-4">
            <div class="container" style="background-color: lightgrey;height: 250px;">

            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group row">
              <label class="col-2 col-form-label">Name:</label>
              <div class="col-10">
                <input type="text" id="txtname" class="form-control" style="text-transform: capitalize;" placeholder="Author Name" v-if="author" v-model="author.name" autocomplete="off">
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <SaveButton  @handle_save_action="saveDocument"></SaveButton>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'

export default {
  name: 'AuthorForm',
  components: { SaveButton },
  data () {
    return {
      author: JSON.parse('{"id":0,"status":"Active","name":"","image":""}')
    }
  },
  store,
  component: {

  },
  props: {
    myauthor: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Active","name":"","image":""}')
    }
  },
  beforeMount () {
    this.author = this.myauthor; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.author = JSON.parse('{"id":0,"status":"Active","name":"","image":""}')
  },
  mounted () {
    $('.form-control-select2').select2();
    $('#txtname').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('author_window_closed');
    },
    saveDocument () {
      const self = this;

      if (self.$data.author.name.toString().length < 3) {
        alert('Invalid Name');
        $('#txtname').focus();
        return;
      }


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      const requestOptions = {
        method: (self.$data.author.id == 0 ? 'POST' : 'PUT'),
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.author)
      }


      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/author`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtname').focus()}, timer:1500 });
          self.author = JSON.parse('{"id":0,"status":"Active","name":"","image":""}');
          self.$emit('author_saved',resp.data);
          $('#txtname').focus();
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    background-color: #feff00!important;
    color: #0a0a0a!important;
  }

</style>
